import SlButton from '@shoelace-style/shoelace/dist/react/button';
import { FC } from 'react';
import { useChat } from '../../../libs/hooks';
import { IAssistant } from '../../../libs/models/Assistants';
import InternalIcon from '../../global/InternalIcon';
import assistantStyles from './AssistantsList.module.scss';

interface AssistantsListProps {
    assistants: Record<string, IAssistant>;
}

export const AssistantsList: FC<AssistantsListProps> = ({ assistants }) => {
    // TODO: Show only 4 pinned assistants
    // TODO: When design is ready, render a "manage assistants" button somewhere
    const assistantNames = Object.keys(assistants);
    const activeAssistants = assistantNames.filter((assistantName) => assistants[assistantName].active);
    const rows = [];
    const chat = useChat();

    // Loop through the assistant names and create rows with 2 buttons each.
    for (let i = 0; i < activeAssistants.slice(0, 3).length; i += 2) {
        rows.push(activeAssistants.slice(i, i + 2));
    }

    const createChat = (assistant: IAssistant) => {
        chat.createChat(
            assistant.title,
            assistant.systemDescription,
            assistant.serviceId,
            assistant.responseTemperature,
            true,
        ).catch((e) => {
            console.error(e);
        });
    };

    return (
        <div className={assistantStyles['assistants-grid']}>
            {rows.map((row, rowIndex) => (
                <div
                    className={`${assistantStyles['button-row']} ${
                        row.length === 1 ? assistantStyles['single-button-row'] : ''
                    }`}
                    key={rowIndex}
                >
                    {row.map((assistantKey, buttonIndex) => (
                        <SlButton
                            onClick={() => {
                                createChat(assistants[assistantKey]);
                            }}
                            className={assistantStyles['grid-button']}
                            key={buttonIndex}
                        >
                            {/* Setting the icon to 22px is equivalent to a 24px lets-icon (lets icons have padding) */}
                            <InternalIcon icon="assistant-bot" width={22} height={22} style={{ paddingLeft: '2px' }} />
                            {assistants[assistantKey].title}
                        </SlButton>
                    ))}
                </div>
            ))}
        </div>
    );
};
