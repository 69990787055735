import React from 'react';

interface InternalIconProps {
    icon: string;
    width?: number;
    height?: number;
    style?: React.CSSProperties;
}

const InternalIcon: React.FC<InternalIconProps> = ({ icon, width = 24, height = 24, style }) => {
    // -----
    // You can add custom icons here if needed. For example if design does not use Lets Icons.
    // Copy the svg code from Figma and paste it here.
    // Replace any colors with currentColor to allow color changes via scss.
    // -----

    const iconsMap: Record<string, JSX.Element> = {
        'assistant-bot': (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                style={style}
                viewBox="0 0 20 22"
                fill="none"
            >
                <path
                    d="M9 10C9 8.895 8.105 8 7 8C5.895 8 5 8.895 5 10C5 11.105 5.895 12 7 12C8.105 12 9 11.105 9 10ZM6 10C6 9.45 6.45 9 7 9C7.55 9 8 9.45 8 10C8 10.55 7.55 11 7 11C6.45 11 6 10.55 6 10Z"
                    fill="currentColor"
                />
                <path
                    d="M13 8C11.895 8 11 8.895 11 10C11 11.105 11.895 12 13 12C14.105 12 15 11.105 15 10C15 8.895 14.105 8 13 8ZM13 11C12.45 11 12 10.55 12 10C12 9.45 12.45 9 13 9C13.55 9 14 9.45 14 10C14 10.55 13.55 11 13 11Z"
                    fill="currentColor"
                />
                <path
                    d="M20 11.6552C20 9.91938 18.8652 8.48151 17.3913 8.2397V6.47367C17.3913 5.28192 16.4174 4.3147 15.2174 4.3147H11.0478L14.6565 0.735129C14.8261 0.566729 14.8261 0.294699 14.6565 0.1263C14.487 -0.0420999 14.213 -0.0420999 14.0435 0.1263L9.82174 4.31902H4.78261C3.58261 4.31902 2.6087 5.28623 2.6087 6.47798V8.24402C1.13478 8.48583 0 9.9237 0 11.6595C0 13.3953 1.13478 14.8332 2.6087 15.075V16.841C2.6087 18.0328 3.58261 19 4.78261 19H15.2174C16.4174 19 17.3913 18.0328 17.3913 16.841V15.075C18.8652 14.8332 20 13.3953 20 11.6595V11.6552ZM0.869565 11.6552C0.869565 10.403 1.61739 9.35805 2.6087 9.11624V14.1941C1.61739 13.9523 0.869565 12.9074 0.869565 11.6552ZM16.5217 16.8367C16.5217 17.5492 15.9348 18.1321 15.2174 18.1321H4.78261C4.06522 18.1321 3.47826 17.5492 3.47826 16.8367V6.47367C3.47826 5.76121 4.06522 5.17829 4.78261 5.17829H15.2174C15.9348 5.17829 16.5217 5.76121 16.5217 6.47367V16.8367ZM17.3913 14.1941V9.11624C18.3826 9.35805 19.1304 10.403 19.1304 11.6552C19.1304 12.9074 18.3826 13.9523 17.3913 14.1941Z"
                    fill="currentColor"
                />
                <path
                    d="M13.5556 21H6.44444C6.2 21 6 21.225 6 21.5C6 21.775 6.2 22 6.44444 22H13.5556C13.8 22 14 21.775 14 21.5C14 21.225 13.8 21 13.5556 21Z"
                    fill="currentColor"
                />
                <path
                    d="M12.3618 14.1398C12.3618 14.1398 11.6682 15.0022 10 15.0022C8.33177 15.0022 7.65698 14.1649 7.63824 14.1448C7.49203 13.9543 7.25586 13.9543 7.10965 14.1448C6.96345 14.3354 6.96345 14.6563 7.10965 14.8518C7.14339 14.8969 7.99063 16 10 16C12.0094 16 12.8529 14.8969 12.8903 14.8518C13.0366 14.6563 13.0366 14.3454 12.8903 14.1498C12.7479 13.9543 12.5117 13.9493 12.3618 14.1398Z"
                    fill="currentColor"
                />
            </svg>
        ),
        'add-chat-plus': (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                viewBox="0 0 25 24"
                fill="none"
                style={style}
            >
                <path
                    d="M12.5 5V5C16.366 5 19.5 8.13401 19.5 12V16.4545C19.5 16.9615 19.5 17.215 19.4543 17.4251C19.2879 18.1902 18.6902 18.7879 17.9251 18.9543C17.715 19 17.4615 19 16.9545 19H12.5C8.63401 19 5.5 15.866 5.5 12V12"
                    stroke="currentColor"
                />
                <path d="M9.5 11L15.5 11" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.5 8L5.5 2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.5 5L8.5 5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.5 15H15.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        ),

        // Add other icons here...
    };

    return iconsMap[icon] || null;
};

export default InternalIcon;
