import { Icon } from '@iconify/react';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import React, { FC, useState } from 'react';

import { useGoPage } from '../../libs/hooks/useRouterNavigate';
import { useAppDispatch } from '../../redux/app/hooks';
import { setShowSideBar } from '../../redux/features/app/appSlice';
import { EditChatName } from './EditChatName';
import { ListItemActions } from './ListItemActions';

import InternalIcon from '../global/InternalIcon';
import toolTipStyles from '../global/tooltip.module.scss';
import chatListStyles from './chatlist.module.scss';
import { isDesktop } from './helpers';

interface IChatListItemProps {
    id: string;
    header: string;
    time: string;
    preview: string;
    isSelected: boolean;
    isAssistant: boolean;
    createdOn: number;
}

const ChatListItem: FC<IChatListItemProps> = ({ id, header, time, preview, isSelected, isAssistant, createdOn }) => {
    const { goChat } = useGoPage();
    const dispatch = useAppDispatch();
    const showActions = isSelected;

    // Set expiration time to 60 days
    const expirationTime = 60 * 24 * 60 * 60 * 1000; // 60 days in milliseconds
    // Set warning time to 57 days
    const warningTime = 57 * 24 * 60 * 60 * 1000; // 57 days in milliseconds
    const timeAgo = Math.floor(Date.now() - new Date(createdOn).getTime());

    const [editingTitle, setEditingTitle] = useState(false);

    const startEditing = () => {
        setEditingTitle(true);
    };
    const stopEditing = () => {
        setEditingTitle(false);
    };

    const selectThisChat = () => {
        if (!isDesktop()) {
            // hiding sidebar on gadgets with small screen
            dispatch(setShowSideBar(false));
        }
        goChat(id);
    };

    const getDaysLeft = () => {
        const daysLeft = Math.floor((expirationTime - timeAgo) / (24 * 60 * 60 * 1000));
        return daysLeft;
    };

    return (
        <div
            className={chatListStyles[isSelected ? 'chat-list-item-selected' : 'chat-list-item']}
            onClick={selectThisChat}
            title={`Chat: ${header}`}
            aria-label={`Chat list item: ${header}`}
        >
            <div className={chatListStyles['chat-icon-wrapper']}>
                {isAssistant ? (
                    <InternalIcon icon="assistant-bot" width={24} height={24} style={{ padding: '0' }} />
                ) : (
                    <Icon icon="lets-icons:chat-light" className={chatListStyles['chat-icon']} />
                )}
                {timeAgo > warningTime && (
                    <SlTooltip
                        content={'Chat expires in ' + getDaysLeft() + ' days'}
                        className={toolTipStyles['sapience-tooltip']}
                    >
                        <Icon
                            icon="lets-icons:time-fill"
                            className={chatListStyles['expiration-badge']}
                            width={14}
                            height={14}
                        />
                    </SlTooltip>
                )}
            </div>

            {editingTitle ? (
                <EditChatName name={header} chatId={id} exitEdits={stopEditing} />
            ) : (
                <>
                    <div className={chatListStyles['item-body']}>
                        <div className={chatListStyles['item-header']}>
                            <span className={chatListStyles['chat-title']} title={header}>
                                {header}
                            </span>
                            {!isSelected && <span className={chatListStyles['time-stamp']}>{time}</span>}
                        </div>
                        {preview ? (
                            <p id={`message-preview-${id}`} className={chatListStyles['preview-text']}>
                                {preview}
                            </p>
                        ) : (
                            <p id={`message-preview-${id}`} className={chatListStyles['preview-text']}>
                                No preview available
                            </p>
                        )}
                    </div>
                    {showActions && <ListItemActions chatId={id} header={header} onEditTitleClick={startEditing} />}
                </>
            )}
        </div>
    );
};

export default React.memo(ChatListItem);
